body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	  sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  
  code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	  monospace;
  }

  input:focus {
	  outline-color: #093fd1;
  }
  
  html {height: 100vh; width: 100vw;}
  body {height: 100%; width: 100%;}
  html, body, #root {padding: 0; margin: 0;}
  #root {
	  position: absolute;
	  top: 0; bottom: 0;
	  left: 0; right: 0;
  
	  display: flex;
	  align-items: stretch;
	  flex-basis: auto;
	  flex-direction: column;
	  z-index: 0;
  }